<script lang="ts" setup>
import { useI18n } from 'nuxt-i18n-composable'

import Heading from '@/components/Heading.vue'
import ResponsiveImage from '@/components/ResponsiveImage.vue'

const emit = defineEmits<{
  (e: 'action', type: OverlayAction, data?: unknown): void
  (e: 'close'): void
}>()

const { t } = useI18n()

const redirect = () => {
  emit('action', 'redirect', { routeName: 'billing' })
  emit('close')
}
</script>

<template>
  <v-dialog
    :value="true"
    fullscreen
    hide-overlay
    persistent
    no-click-animation
    content-class="overlay"
    :transition="false"
  >
    <v-container :fill-height="true">
      <v-row justify="center">
        <v-col cols="12" md="6" order="2" order-md="1">
          <heading
            :title="t('sign.error.no_free_signatures.title')"
            :subtitle="t('sign.error.no_free_signatures.subtitle')"
          />
          <v-btn data-cy="redirectBtn" class="mx-0 mt-0" color="info" large @click="redirect">
            {{ t('sign.error.no_free_signatures.cta') }}
          </v-btn>
          <v-btn text @click="emit('close')">
            {{ t('global.back') }}
          </v-btn>
        </v-col>
        <v-col cols="12" md="6" order="1" order-md="2">
          <responsive-image source="error-no-free-sigs" :width="560" :height="406" />
        </v-col>
      </v-row>
    </v-container>
  </v-dialog>
</template>
